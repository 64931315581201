import { Box } from "@mui/system";
import { Container, Typography } from "@material-ui/core";
// slate serializer and html parser
import ReactHtmlParser from "react-html-parser";
import SlateSerializer from "../utils/SlateSerializer";

export default function AssistanceAlert(props) {
  const classes = { paper: "paper", root: "root fullscreen" };

  return (
    <Container
      sx={{
        display: {
          xs: "none",
          md: "block",
        },
        position: "absolute",
        top: "120px",
        right: {
          md: "50px",
          lg: "120px",
        },
        width: "650px",
        maxWidth: "100%",
        padding: 0,
        zIndex: "9999",
        ...props.sx?.container,
      }}
    >
      <Box
        sx={{
          position: "relative",
          backgroundColor: "white",
          borderRadius: "12px",
          padding: "15px",
          paddingRight: "45px",
          ...props.sx?.boxWrapper,
        }}
      >
        {props.image && (
          <Box
            component="img"
            sx={{
              position: "absolute",
              top: "-25px",
              right: "-50px",
              width: "90px",
              height: "90px",
            }}
            src={props.image}
          />
        )}
        <Typography
          variant="h6"
          color="#B50026"
          sx={{
            fontWeight: "bold",
            marginBottom: "10px",
            fontSize: "16px",
            lineHeight: "100%",
            "& p": {
              margin: 0,
            },
          }}
        >
          {props.title
            ? ReactHtmlParser(
                SlateSerializer({ children: JSON.parse(props.title) })
              )
            : "Hai bisogno di assistenza?"}
        </Typography>
        <Typography
          variant="noSpaceParagraph"
          sx={{
            fontSize: "13px",
          }}
        >
          {ReactHtmlParser(
            "Uno steward è a tua disposizione dal lunedì al venerdì, dalle 9:00 alle 19:00. Ti ascolta, ti supporta e ti affianca nella navigazione all’interno della piattaforma, aiutandoti a comprendere meglio il percorso e i servizi disponibili.<br>Puoi prenotare un appuntamento cliccando sul pulsante Assistenza o contattarlo direttamente al numero +39 3476254288."
          )}
        </Typography>
      </Box>
    </Container>
  );
}
