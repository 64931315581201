import * as React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
// icons
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// @mui
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import useAuth from "../hooks/useAuth";
import useIsMountedRef from "../hooks/useIsMountedRef";

export default function LoginForm({ email }) {
  //const { translate } = useLocales();
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();
  //const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email non valida.")
      .required("Email obbligatoria."),
    password: Yup.string().required("Password obbligatoria."),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      // Remember me
      // remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        await login(values.email, values.password);
        /*enqueueSnackbar('Login success', {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });*/
        if (isMountedRef.current) {
          setSubmitting(false);
        }

        // Redirect to otp verification page
        window.location.href = "/main/dashboard";
      } catch (error) {
        console.error(error);

        if (isMountedRef.current) {
          if (error.status === 422) {
            const errorMessage = Object.values(error.data)[0][0];

            setErrors({ afterSubmit: errorMessage });
            resetForm();
          } else if (error.status === 401) {
            // Reset password
            resetForm({
              values: {
                email: values.email,
                password: "",
              },
            });
            setErrors({ afterSubmit: error.data.message }); // translate('auth.login.username_not_validated')
          } else {
            setErrors({ afterSubmit: error.data.message });
          }

          setSubmitting(false);
        }
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  return (
    <Container component="div" sx={{ m: 0, p: 0 }}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 0,
          m: 0,
        }}
      >
        <Typography
          component="h1"
          variant="h4"
          sx={{ color: "#004990", fontWeight: "bold" }}
        >
          Benvenuto
        </Typography>

        <Typography
          variant="caption"
          sx={{ pt: 1, pb: 3, fontSize: "13px", textAlign: "center" }}
        >
          Accedi con le credenziali che hai utilizzato durante la registrazione
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          {errors.afterSubmit && (
            <Alert severity="error" sx={{ mt: 2, mb: 1 }}>
              {errors.afterSubmit}
            </Alert>
          )}

          <TextField
            margin="normal"
            variant="standard"
            fullWidth
            id="email"
            placeholder="Indirizzo email"
            name="email"
            autoComplete="email"
            autoFocus
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            sx={styles.inputText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutlineIcon sx={{ color: "#6369E9" }} />
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
          />
          <TextField
            margin="normal"
            variant="standard"
            fullWidth
            name="password"
            placeholder="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            sx={styles.inputText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon sx={{ color: "#6369E9" }} />
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
          />

          {/* <FormControlLabel
                control={<Checkbox value="remember" sx={{ m: 0, p: 0, ml: '-2px', pr: '.7rem', color: "#6369E9", borderBlockColor: "#707070" }} />}
                label={<Typography variant="body2">Ricordami</Typography>}
                sx={{
                  m: 0,
                  pt: '.7rem',
                  width: '100%',
                }}
              /> */}

          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            sx={{ mt: 4, textAlign: "center" }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{
                width: "50%",
                p: "8px 50px",
                backgroundColor: "#2CA4DE",
              }}
            >
              Accedi
            </Button>

            <Link href="/auth/password/request" variant="body2">
              Non ricordi la password?
            </Link>
          </Stack>
        </form>
      </Box>
    </Container>
  );
}

const styles = {
  inputText: {
    backgroundColor: "#EEF2F6",
    border: "0px solid",
    p: "1rem",
    borderRadius: "10px",
  },
};
