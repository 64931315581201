import { AccordionDetails } from "@mui/material";
import create from "zustand";
// utils
import axios from "../utils/axios";
import { isValidToken, setSession, getTokenData } from "../utils/jwt";

// ----------------------------------------------------------------------
const token = localStorage.getItem("accessToken");

const getCurrentRole = () => {
  if (isValidToken(token)) {
    const tokenData = getTokenData(token);

    return tokenData.role;
  }

  return "USER";
};

const getOtpVerified = () => {
  const { otp_verified } = getTokenData(token);

  if (otp_verified !== true && otp_verified !== false) {
    return null;
  }

  return otp_verified;
};

const getFirstUseDate = (token) => {
  // Decode base64 token and get the expire date
  const { firstUseDate } = getTokenData(token); // Can be ISO-8601 string or null

  if (firstUseDate) {
    return new Date(firstUseDate); // Convert to Date object
  }

  return null;
};

const getExpireAccessDate = (token) => {
  // Decode base64 token and get the expire date
  const { expireAccessDate } = getTokenData(token); // Can be ISO-8601 string or null

  if (expireAccessDate) {
    return new Date(expireAccessDate); // Convert to Date object
  }

  return null;
};

const initialState = {
  isAuthenticated: isValidToken(token),
  authToken: isValidToken(token) ? token : null,
  isInitialized: true,
  user: isValidToken(token) ? getTokenData(token) : {},
  method: "jwt",
  role: getCurrentRole(),
  isOtpVerified: true, // Always set to true to bypass OTP verification
  firstUseDate: isValidToken(token) ? getFirstUseDate(token) : null,
  expireAccessDate: isValidToken(token) ? getExpireAccessDate(token) : null,
};

const login = async (email, password) => {
  const response = await axios.post("/login", {
    email,
    password,
  });

  const { token: accessToken, user: userF, refreshToken } = response.data;

  setSession(accessToken, refreshToken);

  const { role } = getTokenData(accessToken);

  const { user } = await details();

  /*set({
    isAuthenticated: isValidToken(accessToken),
    user,
    role,
  });*/

  return { accessToken, userF, refreshToken };
};

const logout = async () => {
  const response = await axios.post("/logout");

  setSession(null);
  set({ isAuthenticated: false });

  return response;
};
const register = async (
  email,
  password,
  password_confirmation,
  code,
  name,
  lastName
) => {
  const response = await axios.post("/register", {
    email,
    password,
    password_confirmation,
    code,
    name,
    lastName,
  });

  const { token: accessToken, user, refreshToken } = response.data;

  setSession(accessToken, refreshToken);

  return { accessToken, user, refreshToken };
};

const verifyOtp = async (otpCode) => {
  const response = await axios.post("/otp/verify", { otp_code: otpCode });

  const { token: accessToken, user, refreshToken } = response.data;

  setSession(accessToken, refreshToken);

  return { accessToken, user, refreshToken };
};

const details = async () => {
  const response = await axios.post("/profile/details", {});

  const user = response.data;

  return { user };
};

const useStore = create((set) => ({
  ...initialState,
  login: async (email, password) => {
    const { accessToken, refreshToken } = await login(email, password);

    const { role } = getTokenData(accessToken);

    // const { user } = await details();

    //user = "pippo pappo";

    set({
      isAuthenticated: isValidToken(accessToken),
      // user,
      // role
    });
  },
  logout: () => {
    setSession(null);
    set({ isAuthenticated: false });
  },
  register,
  verifyOtp: async (otpCode) => {
    const { accessToken, refreshToken } = await verifyOtp(otpCode);

    const { role } = getTokenData(accessToken);

    const { user } = await details();

    set({
      isAuthenticated: isValidToken(accessToken),
      user,
      role,
    });
  },
}));

export default useStore;
